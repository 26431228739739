import { LabelProps } from '@/components/elements/Label/Label';
import { __ } from '@/locale';
import { MouseEventHandler } from 'react';

type CategoryOverlay = {
  label: LabelProps;
  text: string;
  cta?: {
    label: string;
    to: string;
  };
};

export type BeautyCategory = {
  img: string;
  name: string;
  q?: string;
  description?: string;
  cta?: string;
  url?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  overlay?: CategoryOverlay;
  hideName?: boolean;
};

export const allBeautyCategories: string[] = [
  'Akupressur',
  'Akupunktur',
  'Alternativmedicin',
  'Ayurveda',
  'Barberare',
  'Biltvätt',
  'Bilvård',
  'Bioresonansterapi',
  'Coachning',
  'Dietister',
  'Djurvård',
  'Däckhotell',
  'Däckverkstad',
  'Fillers',
  'Fotografering',
  'Fotvård',
  'Fransar',
  'Frekvensterapi',
  'Friskvård',
  'Frisör',
  'Golf',
  'Golfträning',
  'Healing',
  'Hudvård',
  'Hund',
  'Hundfrisör',
  'Hypnos',
  'Hälsa',
  'Hälsovård',
  'Hårvård',
  'Injektionsbehandlingar',
  'Katt',
  'Kinesiologi',
  'Kinesisk medicin',
  'Kiropraktik',
  'Kosmetisk tatuering',
  'Kostrådgivning',
  'Kraniosakral terapi',
  'Kroppsterapeuter',
  'Kurser',
  'Laserbehandling',
  'Limousine',
  'Läkare',
  'Makeup',
  'Massage',
  'Medial vägledning',
  'Meditation',
  'Medium',
  'Nagelförlängning',
  'Nagelvård',
  'Naglar',
  'Naprapati',
  'Näringsterapi',
  'Optiker',
  'Ortopedi',
  'Osteopati',
  'Personal shopper',
  'Personlig träning',
  'Plastikkirurgi',
  'Piercing',
  'Profylax',
  'Renovering',
  'Rådgivning',
  'Samtalsterapi',
  'Sjukgymnastik',
  'Sjukvård',
  'Skönhet',
  'Skoinlägg',
  'Spa',
  'Spraytan',
  'Stylist',
  'Städning',
  'Reflexologi',
  'Tandläkare',
  'Tandvård',
  'Tatuering',
  'Taxi',
  'Terapi',
  'Trådning',
  'Träning',
  'Utbildningar',
  'Vaccination',
  'Vaxning',
  'Verkstad',
  'Veterinärer',
  'Zonterapi',
  'Zumba',
  'Ögonfransförlängning',
  'Övrigt',
];

export const beautyCategories = (onSelfTestClick: MouseEventHandler<HTMLButtonElement>): BeautyCategory[] => [
  {
    img: 'har3',
    name: __('hairdresser'),
    q: 'frisör',
    description: __('category.description.hairdresser'),
    cta: __('category.cta.hairdresser'),
  },
  {
    img: 'massage5',
    name: __('massage'),
    q: 'massage',
    description: __('category.description.massage'),
    cta: __('category.cta.massage'),
  },
  {
    img: 'naglar3',
    name: __('nails'),
    q: 'naglar',
    description: __('category.description.nails'),
    cta: __('category.cta.nails'),
  },
  {
    img: 'fransar3',
    name: __('fringes'),
    q: 'fransar',
    description: __('category.description.fringes'),
    cta: __('category.cta.fringes'),
  },
  {
    img: 'skonhet3',
    name: __('beauty'),
    q: 'skönhet',
    description: __('category.description.beauty'),
    cta: __('category.cta.beauty'),
  },
  {
    img: 'hudvard4',
    name: __('skincare'),
    q: 'hudvård',
    description: __('category.description.skincare'),
    cta: __('category.cta.skincare'),
  },
  {
    img: 'fillers3',
    name: __('fillers'),
    q: 'fillers',
    description: __('category.description.fillers'),
    cta: __('category.cta.fillers'),
  },
  {
    img: 'friskvard3',
    name: __('wellness'),
    q: 'friskvård',
    description: __('category.description.wellness'),
    cta: __('category.cta.wellness'),
  },
  {
    img: 'traning3',
    name: __('exercise'),
    q: 'träning',
    description: __('category.description.exercise'),
    cta: __('category.cta.exercise'),
  },
  /**
   * These will be added back again after black friday campaign
   */
  // {
  //   img: 'product-giftcard',
  //   name: __('category.name.giftcard'),
  //   url: '/giftcards/buy',
  //   hideName: true,
  // },
  // {
  //   img: 'product-wellnesscard',
  //   name: __('category.name.wellnesscard'),
  //   url: '/giftcards/buy?ugctype=wellness',
  //   hideName: true,
  // },
];
