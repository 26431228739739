import Label, { LabelProps } from '@/components/elements/Label/Label';
import { ListItem } from '@/components/elements/lists';
import { ListItemProps } from '@/components/elements/lists/ListItem/ListItem';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { ReactNode } from 'react';

type Image = { src: string; alt?: string };

export interface BundlePlaceContentProps {
  id: number;
  placeName?: string;
  image?: Image;
  placePageUrl?: string;
  placeBookWithBundleUrl?: string;
  placeServiceName?: string;
};

export type BundleListContentProps = {
  placeName: string;
  image?: Image;
  price: ReactNode | string;
  usages: string;
  service: string;
  expiry: string;
  label?: LabelProps;
};

export type BundleListItemProps = BundleListContentProps & {
  onClick?: () => void;
  src?: ListItemProps['to'];
} & Pick<ListItemProps, 'leftPadding' | 'rightPadding' | 'underline'>;

const BundleListItem = (props: BundleListItemProps) => {
  return (
    <ListItem
      leftSlot={<CompanyAvatar alt={props.image?.alt ?? ''} size="md" imgUrl={props.image?.src ?? ''} />}
      onClick={props.onClick}
      verticalAlign="top"
      to={props.src}
      underline={props.underline}
      leftPadding={props.leftPadding}
      rightPadding={props.rightPadding}
      rightSlot={props.src && <Icon variant="chevron-s-right" />}>
      <BundleListContent {...props} label={props.label} />
    </ListItem>
  );
};

const BundleListContent = ({ placeName, price, service, usages, expiry, label }: BundleListContentProps) => {
  return (
    <div className="space-y-xs">
      {label && <Label {...label} />}
      <p className="text-black-900 text-md">{placeName}</p>
      <p className="text-black-600 text-sm">{service}</p>
      <p className="text-black-600 text-m">{price}</p>
      <p className="text-black-600 text-m">{usages}</p>
      <p className="text-black-600 text-m">{expiry}</p>
    </div>
  );
};

export default BundleListItem;
