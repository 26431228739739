import { bookActions } from '@/actions';
import Header from '@/components/elements/Header/Header';
import { LabelProps } from '@/components/elements/Label/Label';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import BookingListItem from '@/components/elements/lists/BookingListItem/BookingListItem';
import BundleOtherLocationsListItem from '@/components/elements/lists/BundleOtherLocationsListItem/BundleOtherLocationsListItem';
import DataGrid from '@/components/elements/lists/DataGrid';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import EmptyState from '@/components/templates/EmptyState';
import SelectedPaymentMethod from '@/components/templates/checkout/SelectedPaymentMethod/SelectedPaymentMethod';
import { SCREEN_NAME } from '@/constants/analytics';
import {
  BOOK_TIME_ACTION_ITEM,
  BUY_AGAIN_ACTION_ITEM,
  CONSUMED_BUNDLE_LABEL,
  EXPIRED_BUNDLE_LABEL,
  VALID_BUNDLE_LABEL,
} from '@/constants/bundle';
import { CALENDAR_ICON_FILENAME } from '@/constants/icons';
import { TERMS_NAVIGATION_LINKS, TermLinkIdentifier } from '@/constants/terms';
import { isSistaminuten, promiseWrapper, setBookingStartingPoint, trackMpEvent } from '@/helpers';
import {
  getBundleListContentProps,
  getBundleUsagesLeftString,
  isBundleActive,
  isBundleConsumed,
  isBundleExpired,
  isBundleValid,
  getOtherBundleLocations,
} from '@/helpers/bundle';
import {
  mapConfirmedBookingToCheckoutDetailsProps,
  mapConfirmedBundleToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { userService } from '@/services';
import { handleLoginClick } from '@/services/navigationServices';
import { ScreenViewEvent } from '@/types/analytics';
import { ClientBundle, getBundleByIdResponseSchema } from '@/types/api/services/users/schema';
import { BundleActionIdentifier, BundleActionItem } from '@/types/bundle';
import { NavItemIdentifier } from '@/types/navigation';
import { User } from '@/types/user';
import { Dispatch, Fragment, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

type BundleDetailsState = {
  bundle: ClientBundle;
  error: boolean;
  loading: boolean;
};

type BundleDetailsAction =
  | {
      type: 'SET_BUNDLE';
      payload: ClientBundle;
    }
  | {
      type: 'ERROR';
    };

const baseTranslationKey = 'pages.klippkort.$id.BundleDetails';

const getScreenViewEvent = (bundle: ClientBundle, user: User): ScreenViewEvent => {
  return {
    name: 'screen_view_bundle_details',
    properties: { booking_bundles_id: bundle.id, company_id: bundle.place.id, customer_id: user.id },
  };
};

const BundleDetails = ({ bundle }: { bundle: ClientBundle }) => {
  const { isMobileView } = useMobileView();
  const history = useHistory();
  const location = useLocation();
  const user = useAppSelector((state) => state.users)?.user;
  const dispatch = useAppDispatch();

  const checkoutDetailsProps = mapConfirmedBundleToCheckoutDetailsProps({ bundle });
  const hasMadeBookingsWithBundle = Boolean(bundle.bookings?.length);
  const { expiry, ...bookingListItemProps } = getBundleListContentProps(bundle);
  const label: LabelProps = (() => {
    if (isBundleExpired(bundle)) return EXPIRED_BUNDLE_LABEL;
    if (isBundleConsumed(bundle)) return CONSUMED_BUNDLE_LABEL;
    return VALID_BUNDLE_LABEL;
  })();

  const termDescription = _s(`${baseTranslationKey}.terms.description`);
  const { label: termLabel, to: termLink } = TERMS_NAVIGATION_LINKS.find(
    (term) => term.identifier === TermLinkIdentifier.PAYMENT,
  );

  const handleBackButtonClick = () => {
    history.push({
      pathname: location?.state?.from ?? '/klippkort/giltig',
      state: { fromDetails: true },
    });
  };

  useTrackScreenView(getScreenViewEvent(bundle, user));

  const { place } = bundle;
  const placePageUrl = place?.about?.active ? `/places/${place?.about?.slug}-${place?.id}` : null;

  const otherPlaces =
    isBundleValid(bundle) && bundle?.places ? bundle.places.filter((p) => p.id !== bundle?.place?.id) : [];
  const otherLocationsItemProps = getOtherBundleLocations(otherPlaces);

  const handleOtherLocationBundleActionClick = (bundle: ClientBundle, otherPlaceId: number) => {
    const identifier = BundleActionIdentifier.BOOK_TIME;
    trackMpEvent(`${identifier}_click`, { trigger_source: SCREEN_NAME.BUNDLE_DETAILS });

    const otherPlace = bundle.places.find((place) => place.id === otherPlaceId);

    // Create a new bundle object
    const otherPlaceBundle = {
      ...bundle,
      place: {
        ...bundle.place,
        id: otherPlace.id,
        about: otherPlace.about,
      },
      service: {
        ...bundle.service,
        serviceId: otherPlace.service.id,
        slug: otherPlace.service.slug,
      },
    };

    setBookingStartingPoint('my_bundles');
    dispatch(bookActions.applyBundle(otherPlaceBundle));
  };

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="lg:py-xxl lg:container">
        <div className="gap-xxl flex items-start">
          <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
            <CardWrapper className="bg-white">
              <BookingListItem
                {...bookingListItemProps}
                performer={_s('anyEmployee')}
                label={label}
                src={placePageUrl}
              />
              <div className="px-lg">
                <DataGrid ariaLabel={_s(`${baseTranslationKey}.ariaLabel.title`)}>
                  {checkoutDetailsProps.bundles?.map(({ name, price, discount }, key) => {
                    const isLastBundle = key === checkoutDetailsProps.bundles.length - 1;
                    return (
                      <Fragment key={name + key}>
                        <DataGrid.Row key={key} hasPaddingTop>
                          <DataGrid.Cell title={name} type="muted" />
                          <DataGrid.Cell
                            title={
                              discount ? (
                                <span className="text-highlight-700">
                                  {discount} <span className="text-black-600 line-through">({price})</span>
                                </span>
                              ) : (
                                price
                              )
                            }
                            align="right"
                          />
                        </DataGrid.Row>
                        <DataGrid.Row>
                          <DataGrid.Cell title={`(${getBundleUsagesLeftString(bundle)})`} type="description" />
                        </DataGrid.Row>
                        <DataGrid.Row {...(isLastBundle && { undeline: true })}>
                          <DataGrid.Cell title={expiry} type="description" />
                        </DataGrid.Row>
                      </Fragment>
                    );
                  })}
                  <DataGrid.Row hasPaddingTop>
                    <DataGrid.Cell title={checkoutDetailsProps.priceToPay.label} type="header" />
                    <DataGrid.Cell
                      title={checkoutDetailsProps.priceToPay.price}
                      type="header"
                      align="right"
                      ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
                    />
                  </DataGrid.Row>
                </DataGrid>
              </div>
              {checkoutDetailsProps.paymentMethod && (
                <SelectedPaymentMethod method={checkoutDetailsProps.paymentMethod} />
              )}
              <Alert
                variant="information"
                body={
                  <>
                    {termDescription}{' '}
                    <Link className="underline" to={termLink}>
                      {termLabel}
                    </Link>
                  </>
                }
              />
            </CardWrapper>

            {otherLocationsItemProps?.length > 0 && (
              <CardWrapper className="bg-white">
                <div className="pl-lg">
                  <Header label={_s(`${baseTranslationKey}.section.otherLocations.title`)} size="lg" />
                
                  {otherLocationsItemProps.map((otherLocation) => (
                    <BundleOtherLocationsListItem
                      key={otherLocation.id}
                      onClick={() => handleOtherLocationBundleActionClick(bundle, otherLocation.id)}
                      {...otherLocation}
                    />
                  ))}
                </div>
              </CardWrapper>
            )}

            <CardWrapper className="bg-white">
              <div className="py-lg pl-lg">
                <Header label={_s(`${baseTranslationKey}.section.bookings.title`)} size="lg" />
              </div>
              {!hasMadeBookingsWithBundle && (
                <EmptyState
                  title={_s(`${baseTranslationKey}.section.bookings.emptystate.title`)}
                  body={_s(`${baseTranslationKey}.section.bookings.emptystate.body`)}
                  icon={<Icon variant="calendar-empty" style={{ width: 128, height: 128 }} color="black-300" />}
                />
              )}
              {hasMadeBookingsWithBundle &&
                bundle.bookings?.map((booking) => {
                  const checkoutDetailsProps = mapConfirmedBookingToCheckoutDetailsProps({ booking });
                  const services =
                    booking.services[0].service.name +
                    (booking.services.length > 1
                      ? ' (+ ' + (booking.services.length - 1) + ` ${_s('moreAmount')})`
                      : '');
                  const src = {
                    pathname: `/bokning/${booking.id}`,
                    state: { appointment: booking, from: `/klippkortdetaljer/${bundle.id}` },
                  };

                  return (
                    <BookingListItem
                      key={booking.id}
                      dateTime={checkoutDetailsProps.dateTime}
                      performer={checkoutDetailsProps.performer}
                      placeName={checkoutDetailsProps.placeName}
                      services={services}
                      image={{ src: checkoutDetailsProps.logo }}
                      src={src}
                    />
                  );
                })}
            </CardWrapper>

            <CardWrapper className="w-full lg:hidden">
              <div className="p-lg empty:hidden">
                <BundleActions bundle={bundle} />
              </div>
            </CardWrapper>
          </div>
          <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
            <CardWrapper className="w-full empty:hidden">
              <BundleActions bundle={bundle} />
            </CardWrapper>
          </div>
        </div>
      </div>
    </PageViewLayout>
  );
};

const BundleActions = ({ bundle }: { bundle: ClientBundle }) => {
  const dispatch = useAppDispatch();
  const bookWithBundlePathname = `/boka-tjanst/${bundle.place.about.slug}-${bundle.place.id}/${bundle.service.slug}-${bundle.service.serviceId}`;
  const buyAgainPathname = `/bundles/checkout/${bundle.place.id}/${bundle.bundleTemplateId}`;

  const actions: BundleActionItem[] = [
    ...(isBundleValid(bundle) ? [{ ...BOOK_TIME_ACTION_ITEM, to: bookWithBundlePathname, icon: CALENDAR_ICON_FILENAME.CALENDAR_1 }] : []),
    ...(isBundleActive(bundle) ? [{ ...BUY_AGAIN_ACTION_ITEM, to: buyAgainPathname }] : []),
  ];

  const handleActionClick = (identifier: BundleActionIdentifier) => {
    if (identifier === BundleActionIdentifier.BOOK_TIME) {
      setBookingStartingPoint('my_bundles');
      dispatch(bookActions.applyBundle(bundle));
    }
  };

  return (
    actions.length > 0 && (
      <div className="space-y-md flex w-full flex-col">
        {actions.map((action, key) => {
          const Action = action.to ? LinkButton : Button;
          const isFirstItem = key === 0;
          const variant = action.variant === 'link' ? 'link' : isFirstItem ? 'primary' : 'secondary';
          const iconProps = (() => {
            if (!action.icon) return null;
            return { variant: action.icon, color: action.iconColor };
          })();
          return (
            <Action
              key={action.identifier}
              label={action.label}
              variant={variant}
              size="md"
              onClick={() => handleActionClick(action.identifier)}
              {...(action.to && { to: action.to })}
              {...(iconProps && { leftIcon: <Icon {...iconProps} /> })}
            />
          );
        })}
      </div>
    )
  );
};

async function fetchAndSetBundleDetails(id: number, dispatch: Dispatch<BundleDetailsAction>) {
  const { data, error } = await promiseWrapper(userService.getBundleById(id));

  const validate = getBundleByIdResponseSchema.safeParse(data);

  if (validate.success === false || error) {
    dispatch({ type: 'ERROR' });
    return;
  }

  dispatch({ type: 'SET_BUNDLE', payload: validate.data });
}

function bundleReducer(state: BundleDetailsState, action: BundleDetailsAction) {
  switch (action.type) {
    case 'SET_BUNDLE':
      return {
        bundle: action.payload,
        loading: false,
        error: false,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      const never: never = action;
      throw new Error(`Unhandled action type: ${never}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const routeMatch = useRouteMatch();
  const user = useAppSelector((state) => state.users)?.user;
  const appDispatch = useAppDispatch();
  const { isMobileView } = useMobileView();
  const isLoggedInUser = Boolean(user && user.token) || isSistaminuten();
  const { id } = routeMatch.params;

  const [state, dispatch] = useReducer(bundleReducer, {
    bundle: undefined,
    error: false,
    loading: true,
  });

  useEffect(() => {
    if (!isLoggedInUser) return;
    fetchAndSetBundleDetails(id, dispatch);
  }, [id, isLoggedInUser]);

  if (!isLoggedInUser) {
    return (
      <PageViewLayout
        type="subView"
        title={_s(`${baseTranslationKey}.page-title`)}
        back
        backSrc="/klippkort/giltig"
        wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
        <EmptyState
          title={_s(`${baseTranslationKey}.emptystate.title`)}
          body={_s(`${baseTranslationKey}.emptystate.body`)}
          icon={<img src="/images/illustrations/bundles.png" alt="" />}
          cta={
            <Button
              label={_s(`${baseTranslationKey}.emptystate.cta`)}
              size="sm"
              onClick={() => handleLoginClick(appDispatch, NavItemIdentifier.LogIn, SCREEN_NAME.BUNDLE_DETAILS)}
            />
          }
        />
      </PageViewLayout>
    );
  }
  if (state.loading) return <LoadingPlaceHolder />;
  if (state.error) return <GoBack />;

  return <BundleDetails bundle={state.bundle} />;
}
