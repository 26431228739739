export const ARROW_ICON_FILENAME = {
  ARROW_DOWN_CIRCLE: 'arrow-down-circle',
  ARROW_DOWN: 'arrow-down',
  ARROW_LEFT_CIRCLE: 'arrow-left-circle',
  ARROW_LEFT: 'arrow-left',
  ARROW_RIGHT_CIRCLE: 'arrow-right-circle',
  ARROW_RIGHT: 'arrow-right',
  ARROW_UP_CIRCLE: 'arrow-up-circle',
  ARROW_UP: 'arrow-up',
  ARROW_UP_DOWN: 'arrow-up-down',
  BACK: 'back',
  CHEVRON_DOUBLE_LEFT: 'chevron-double-left',
  CHEVRON_DOUBLE_RIGHT: 'chevron-double-right',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_S_DOWN: 'chevron-s-down',
  CHEVRON_S_LEFT: 'chevron-s-left',
  CHEVRON_S_RIGHT: 'chevron-s-right',
  CHEVRON_S_UP: 'chevron-s-up',
  CHEVRON_UP: 'chevron-up',
  ENLARGE: 'enlarge',
  REFRESH: 'refresh',
} as const;

export const DEVICE_ICON_FILENAME = {
  MOUSE_ALT: 'mouse-alt',
  MOUSE: 'mouse',
} as const;

export const BRAND_ICON_FILENAME = {
  APPLE_ALT: 'apple-alt',
  APPLE: 'apple',
  BOKADIREKT: 'bokadirekt',
  FACEBOOK_ALT: 'facebook-alt',
  FACEBOOK: 'facebook',
  GOOGLE_ALT: 'google-alt',
  GOOGLE: 'google',
  INSTAGRAM: 'instagram',
  KLARNA: 'klarna',
  LINKEDIN: 'linkedin',
  MICROSOFT365: 'microsoft365',
  QLIRO: 'qliro',
  SISTAMINUTENTIDER: 'sistaminutentider',
  SMT: 'smt',
  SMT_FILLED: 'smt-filled',
  SWISH: 'swish',
  OUTLOOK: 'outlook',
  TRUSTLY: 'trustly',
  YAHOO: 'yahoo',
} as const;

export const CALENDAR_ICON_FILENAME = {
  CALENDAR_1: 'calendar-1',
  CALENDAR_ADD: 'calendar-add',
  CALENDAR_CHECK: 'calendar-check',
  CALENDAR_CROSS: 'calendar-cross',
  CALENDAR_EMPTY: 'calendar-empty',
  CALENDAR_EVENT: 'calendar-event',
  CALENDAR_EVENTS_ALT: 'calendar-events-alt',
  CALENDAR_EVENTS: 'calendar-events',
  CALENDAR_MINUS: 'calendar-minus',
  CALENDAR_PLUS: 'calendar-plus',
  CALENDAR_END: 'calendar-end',
  CALENDAR_START: 'calendar-start',
} as const;

export const CATEGORY_ICON_FILENAME = {
  BARBER_SCISSORS: 'barber-scissors',
  BEAUTY: 'beauty',
  FEET: 'feet',
  FILLERS_ALT: 'fillers-alt',
  FILLERS: 'fillers',
  LASHES: 'lashes',
  NAILS: 'nails',
  PRODUCT: 'product',
  PRODUCTS: 'products',
  SKINCARE: 'skincare',
  TREATMENT: 'treatment',
  WORKOUT: 'workout',
} as const;

export const FILE_ICON_FILENAME = {
  BRIEF: 'brief',
  FILE_ALT: 'file-alt',
  FILE: 'file',
  FOLDER_ADD: 'folder-add',
  FOLDER: 'folder',
  INVOICE: 'invoice',
  RECEIPT: 'receipt',
} as const;

export const FLAGS_ICON_FILENAME = {
  SWEDISH: 'swedish',
  ENGLISH: 'english',
} as const;

export const PAYMENT_ICON_FILENAME = {
  BANK_BUILDING: 'bank-building',
  CAMPAIGN: 'campaign',
  CASH_REGISTER: 'cash-register',
  CASH_STACK: 'cash-stack',
  CASH: 'cash',
  CREDIT_CARD: 'credit-card',
  DEAL: 'deal',
  GIFT_CARD_ALT: 'gift-card-alt',
  GIFT_CARD: 'gift-card',
  GIFT: 'gift',
  MERCHANT_ACCOUNT: 'merchant-account',
  POS_TERMINAL: 'pos-terminal',
  PRE_PAY: 'pre-pay',
  MONEY: 'money',
  SERVICE: 'service',
  SHOP_ALT: 'shop-alt',
  SHOP: 'shop',
  WALLET: 'wallet',
  WELLNESS_CARD: 'wellness-card',
  BUNDLE: 'bundle',
  SHOPPING_BASKET: 'shopping-basket',
  SHOPPING_CART: 'shopping-cart',
  PUNCH_CARD: 'punch-card',
  STAMP_CARD: 'stamp-card',
} as const;

export const PAYMENT_METHODS_ICON_FILENAME = {
  PAYMENT_METHOD_AMEX: 'payment-method-amex',
  PAYMENT_METHOD_APPLE_PAY: 'payment-method-apple-pay',
  PAYMENT_METHOD_GOOGLE_PAY: 'payment-method-google-pay',
  PAYMENT_METHOD_CREDIT_CARD: 'payment-method-credit-card',
  PAYMENT_METHOD_DINERS: 'payment-method-diners',
  PAYMENT_METHOD_DISCOVER: 'payment-method-discover',
  PAYMENT_METHOD_MASTERCARD: 'payment-method-mastercard',
  PAYMENT_METHOD_TRUSTLY: 'payment-method-trustly',
  PAYMENT_METHOD_VISA: 'payment-method-visa',
  PAYMENT_METHOD_GIFT_CARD: 'payment-method-gift-card',
  PAYMENT_METHOD_GIFT_CARD_IGC: 'payment-method-gift-card-igc',
  PAYMENT_METHOD_VALUE_CARD: 'payment-method-value-card',
  PAYMENT_METHOD_WELLNESS_CARD: 'payment-method-wellness-card',
  PAYMENT_METHOD_SWISH: 'payment-method-swish',
  PAYMENT_METHOD_QLIRO: 'payment-method-qliro',
  PAYMENT_METHOD_KLARNA: 'payment-method-klarna',
  PAYMENT_METHOD_MAESTRO: 'payment-method-maestro',
  PAYMENT_METHOD_AMEX_LG: 'payment-method-amex-lg',
  PAYMENT_METHOD_APPLE_PAY_LG: 'payment-method-apple-pay-lg',
  PAYMENT_METHOD_GOOGLE_PAY_LG: 'payment-method-google-pay-lg',
  PAYMENT_METHOD_CREDIT_CARD_LG: 'payment-method-credit-card-lg',
  PAYMENT_METHOD_DINERS_LG: 'payment-method-diners-lg',
  PAYMENT_METHOD_DISCOVER_LG: 'payment-method-discover-lg',
  PAYMENT_METHOD_MASTERCARD_LG: 'payment-method-mastercard-lg',
  PAYMENT_METHOD_TRUSTLY_LG: 'payment-method-trustly-lg',
  PAYMENT_METHOD_VISA_LG: 'payment-method-visa-lg',
  PAYMENT_METHOD_GIFT_CARD_LG: 'payment-method-gift-card-lg',
  PAYMENT_METHOD_GIFT_CARD_IGC_LG: 'payment-method-gift-card-igc-lg',
  PAYMENT_METHOD_VALUE_CARD_LG: 'payment-method-value-card-lg',
  PAYMENT_METHOD_WELLNESS_CARD_LG: 'payment-method-wellness-card-lg',
  PAYMENT_METHOD_SWISH_LG: 'payment-method-swish-lg',
  PAYMENT_METHOD_QLIRO_LG: 'payment-method-qliro-lg',
  PAYMENT_METHOD_KLARNA_LG: 'payment-method-klarna-lg',
  PAYMENT_METHOD_MAESTRO_LG: 'payment-method-maestro-lg',
  PAYMENT_METHOD_BANK_ID: 'payment-method-bank-id',
} as const;

export const SHAPES_ICON_FILENAME = {
  CANCEL: 'cancel',
  CHECK_CIRCLE_ALT: 'check-circle-alt',
  CHECK_CIRCLE: 'check-circle',
  CHECK: 'check',
  CIRCLE: 'circle',
  CLOSE_CIRCLE: 'close-circle',
  CLOSE: 'close',
  CLOSE_ALT: 'close-alt',
  CROSS_SQUARE: 'cross-square',
  ERROR: 'error',
  HELP_CIRCLE: 'help-circle',
  INFO_CIRCLE: 'info-circle',
  INFO_SQUARE: 'info-square',
  OCTAGON: 'octagon',
  PAUSE_CIRCLE: 'pause-circle',
  PLAY_CIRCLE: 'play-circle',
  PLUS_CIRCLE: 'plus-circle',
  PLUS_SQUARE: 'plus-square',
  PLUS: 'plus',
  SQUARE: 'square',
  TRIANGLE: 'triangle',
  WARNING: 'warning',
} as const;

export const TIME_ICON_FILENAME = {
  CLOCK_ADD: 'clock-add',
  CLOCK_DUPLICATE: 'clock-duplicate',
  CLOCK: 'clock',
  REMINDER: 'reminder',
  TIME_CARD: 'time-card',
  TIME_SPAN: 'time-span',
  TIMER: 'timer',
  TIMEZONE: 'timezone',
} as const;

export const USER_ICON_FILENAME = {
  ACCOUNT: 'account',
  CHANGE_USER: 'change-user',
  CONTACT_CARD: 'contact-card',
  USER_ADD: 'user-add',
  USER_INFO: 'user-info',
  USER_MINUS: 'user-minus',
  USER: 'user',
  USERS: 'users',
  USERS_ALT: 'users-alt',
} as const;

export const TEXT_EDIT_ICON_FILENAME = {
  EXPAND: 'expand',
} as const;

export const UI_ICON_FILENAME = {
  BOOKMARK: 'bookmark',
  CALCULATOR: 'calculator',
  COMMENTS: 'comments',
  CAMERA: 'camera',
  CERTIFICATE: 'certificate',
  CHECKBOX_CHECKED_ROUND: 'checkbox-checked-round',
  CHECKBOX_CHECKED_FILLED: 'checkbox-checked-filled',
  CODE: 'code',
  COG: 'cog',
  COMPANY: 'company',
  COMPASS_FILLED: 'compass-filled',
  DOWNLOAD: 'download',
  DRAG_ARROWS: 'drag-arrows',
  DRAG: 'drag',
  DROP: 'drop',
  EDIT: 'edit',
  EMAIL: 'email',
  EXTERNAL_LINK: 'external-link',
  EYE_CLOSED: 'eye-closed',
  EYE_OPEN: 'eye-open',
  FILTER: 'filter',
  FLAG: 'flag',
  GLOBE: 'globe',
  HEART_FILLED: 'heart-filled',
  HEART: 'heart',
  HOME: 'home',
  INDUSTRY_ORGANIZATION: 'industry-organization',
  IMAGE: 'image',
  LIST_OL: 'list-ol',
  LIST_UL: 'list-ul',
  LOCATION: 'location',
  LOCATION_2: 'location-2',
  LOCK_LOCKED: 'lock-locked',
  LOCK_UNLOCKED: 'lock-unlocked',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MAP: 'map',
  MEGAPHONE: 'megaphone',
  MENU: 'menu',
  MORE_HORIZONTAL: 'more-horizontal',
  MORE_VERTICAL: 'more-vertical',
  NAME_TAG: 'name-tag',
  NEWS: 'news',
  NOTE: 'note',
  NOTIFICATION: 'notification',
  PALETTE: 'palette',
  PARTY: 'party',
  PAPERCLIP: 'paperclip',
  PEN: 'pen',
  PHONE: 'phone',
  PRINT: 'print',
  RADIO_CHECKED: 'radio-checked',
  RADIO_UNCHECKED: 'radio-unchecked',
  REPORTS: 'reports',
  ROCKET: 'rocket',
  SEARCH_CHECK: 'search-check',
  SEARCH: 'search',
  SETTINGS: 'settings',
  SHARE: 'share',
  SMS_ALT: 'sms-alt',
  SMS: 'sms',
  SORT: 'sort',
  SORT_ALPHA_DOWN_ALT: 'sort-alpha-down-alt',
  SORT_ALPHA_DOWN: 'sort-alpha-down',
  SORT_ALPHA_UP_ALT: 'sort-alpha-up-alt',
  SORT_ALPHA_UP: 'sort-alpha-up',
  SORT_AMOUNT_DOWN: 'sort-amount-down',
  SORT_AMOUNT_UP: 'sort-amount-up',
  SORT_NUMERIC_DOWN: 'sort-numeric-down',
  SORT_NUMERIC_UP: 'sort-numeric-up',
  STAR_FILLED: 'star-filled',
  STAR_FILLED_HALF: 'star-filled-half',
  STAR_EMPTY: 'star-empty',
  SUPPORT: 'support',
  TAG: 'tag',
  TAGS: 'tags',
  THUMBS_DOWN: 'thumbs-down',
  THUMBS_UP: 'thumbs-up',
  TRASH_ALT: 'trash-alt',
  TRASH: 'trash',
  WEBSITE: 'website',
  WELLNESS: 'wellness',
  ZOOM_IN: 'zoom-in',
  ZOOM_OUT: 'zoom-out',
  HOUSE: 'house',
  COPY: 'copy',
} as const;

export const NOTIFICATION_ICON_FILENAME = {
  NOTIFICATION_OFF: 'notification-off',
  NOTIFICATION_SNOOZE: 'notification-snooze',
  NOTIFICATION_BLOCK: 'notification-block',
  APPROVE_PAYMENT: 'approve-payment',
  NO_SHOW: 'no-show',
  CANCELATION: 'cancelation',
  NEW_BOOKING: 'new-booking',
  RECEPTIONIST: 'receptionist',
  NEW_RELEASE: 'new-release',
  VERIFICATION: 'verification',
} as const;
