import { LoginSuccess } from '@/components/animations';
import Header from '@/components/elements/Header/Header';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import { default as CheckoutDetails } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import AddToCalendarModal, { useAddToCalendarModal } from '@/components/modules/modals/AddToCalendar';
import EmptyState from '@/components/templates/EmptyState';
import { config } from '@/config';
import { SCREEN_NAME } from '@/constants/analytics';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { isPlaceFavorite } from '@/helpers';
import {
  mapConfirmedBookingInfoGiftcards,
  mapConfirmedBookingInfoServices,
  mapConfirmedBookingToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import { useAppSelector } from '@/hooks';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import useConfirmation from './BookingConfirmation.hooks';

const baseTranslationKey = 'pages.booking.confirmation.BookingConfirmation';

let showAddPlaceToFavorites: boolean;

const BookingConfirmation = () => {
  const {
    booking,
    selectedPaymentMethod,
    isActivatePaymentFlow,
    loading,
    error,
    redirect,
    bookingTrackingProps,
    nextStepsAction,
  } = useConfirmation();
  const {
    onOpen: handleOpenCalendarModal,
    onClose: onCloseCalendarModal,
    ...addToCalendarProps
  } = useAddToCalendarModal(booking);
  const addPlaceToFavoritesButton = useRef<HTMLButtonElement>(null);
  const bookSameServiceButton = useRef<HTMLButtonElement>(null);
  const user = useAppSelector((state) => state.users)?.user;

  useTrackScreenView(
    {
      name: 'screen_view_checkout_add_to_calendar',
      properties: {
        triggerSource: isActivatePaymentFlow
          ? SCREEN_NAME.CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD_CONFIRMATION
          : SCREEN_NAME.CHECKOUT_BOOKING_CONFIRMATION,
      },
    },
    [addToCalendarProps.isOpen],
    [addToCalendarProps.isOpen],
  );

  useEffect(() => {
    const placeId = booking?.place?.id;
    if (user && placeId) {
      showAddPlaceToFavorites ||= !isPlaceFavorite(user, placeId);
    }
  }, [user, booking?.place?.id]);

  /**
   * If klarna or qliro confirmation fails (if time is no longer available), client will be redirected back
   * to calendar view with an error message saying time is busy and payment will be released.
   */
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) return <LoadingPlaceHolder text={_s('confirmationLoader')} />;

  const checkoutDetailsProps = mapConfirmedBookingToCheckoutDetailsProps({ booking, selectedPaymentMethod });
  const services = mapConfirmedBookingInfoServices(booking);
  const giftcards = mapConfirmedBookingInfoGiftcards(booking);
  const placePageUrl = `/places/${booking.place.about.slug}-${booking.place.id}`;
  const bookingOriginator = sessionStorage.getItem('bookSPurl') ?? placePageUrl;
  const clientEmail = booking.extra?.formfields?.[1]?.email;
  const cancellationCode = booking.services?.find((service) => service.cancellationCode)?.cancellationCode;

  const handleAddPlaceToFavorites = () => {
    addPlaceToFavoritesButton?.current?.blur();
    nextStepsAction().onAddPlaceToFavorites();
  };

  const handleBookSameService = () => {
    bookSameServiceButton?.current?.blur();
    nextStepsAction().onBookSameService();
  };

  const handleCloseCalendarModal = () => {
    onCloseCalendarModal();

    if (isActivatePaymentFlow) {
      trackScreenView({
        name: 'screen_view_checkout_booking_update_payment_method_confirmation',
        properties: { ...bookingTrackingProps?.paymentMethodsProps },
      });
      return;
    }

    trackScreenView({
      name: 'screen_view_checkout_booking_confirmation',
      properties: {
        ...bookingTrackingProps?.bookingProps,
        ...bookingTrackingProps?.paymentMethodsProps,
        ...bookingTrackingProps?.extraProps,
      },
    });
  };

  return (
    <PageViewLayout
      type="subView"
      wrapperClass="lg:bg-gradient"
      rightSlot={
        <Link to={bookingOriginator}>
          <Icon variant="close-alt" alt={_s('close')} size="sm" />
        </Link>
      }>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="bg-white">
                <EmptyState
                  icon={<LoginSuccess style={{ width: 196, height: 154 }} />}
                  title={_s(`${baseTranslationKey}.header${isActivatePaymentFlow ? '.activatePayment' : ''}.title`)}
                  body={
                    <span className="space-y-lg text-black-900 flex flex-col">
                      {!user && cancellationCode && (
                        <span className="flex flex-col">
                          <span>
                            {_s(`${baseTranslationKey}.header.body.cancellationCode`)}{' '}
                            <span className="font-semibold">{cancellationCode}</span>
                          </span>
                          <span>{_s(`${baseTranslationKey}.header.body.cancellationLink`)}</span>
                          <Link to={`/cancel?code=${cancellationCode}`}>{config.baseUrl}cancel</Link>
                        </span>
                      )}
                      {clientEmail && <p>{_s(`${baseTranslationKey}.header.body.email`, { email: clientEmail })}</p>}
                    </span>
                  }
                />
              </CardWrapper>
              {checkoutDetailsProps && (
                <div className="lg:hidden">
                  {
                    <CardWrapper className="bg-white">
                      <CheckoutDetails {...checkoutDetailsProps} services={services} giftcards={giftcards} />
                    </CardWrapper>
                  }
                </div>
              )}
              <CardWrapper className="bg-information-50">
                <div className="p-lg">
                  <div className="py-sm">
                    <Header label={_s(`${baseTranslationKey}.appDownload.title`)} size="md" />
                  </div>
                  <p className="text-black-600 text-md">{_s(`${baseTranslationKey}.appDownload.body`)}</p>
                  <div className="pt-lg flex items-center justify-center">
                    <a className="align-center justify-left flex flex-wrap" href={SMART_LINKS.CONFIRMED}>
                      <img
                        src="/images/download-app-store.png"
                        className="mr-3 h-[40px]"
                        alt={_s('availableAppStore')}
                      />
                      <img src="/images/download-google-play.png" className="h-[40px]" alt={_s('availablePlayStore')} />
                    </a>
                  </div>
                </div>
              </CardWrapper>
              <CardWrapper className="bg-white">
                <div className="p-lg gap-lg flex flex-col">
                  <Button
                    block
                    variant="primary"
                    leftIcon={<Icon variant="calendar-check" />}
                    label={_s(`${baseTranslationKey}.footer.cta.addToCalendar`)}
                    onClick={handleOpenCalendarModal}
                    size="md"
                  />
                  {showAddPlaceToFavorites && (
                    <Button
                      ref={addPlaceToFavoritesButton}
                      block
                      onClick={handleAddPlaceToFavorites}
                      variant="secondary"
                      leftIcon={
                        <Icon
                          {...(isPlaceFavorite(user, booking?.place?.id) && { color: 'danger-500' })}
                          variant={isPlaceFavorite(user, booking?.place?.id) ? 'heart-filled' : 'heart'}
                        />
                      }
                      label={
                        isPlaceFavorite(user, booking?.place?.id)
                          ? _s(`${baseTranslationKey}.footer.cta.addToFavorites.remove`)
                          : _s(`${baseTranslationKey}.footer.cta.addToFavorites.add`)
                      }
                      size="md"
                    />
                  )}
                  {!showAddPlaceToFavorites && !isActivatePaymentFlow && (
                    <Button
                      ref={bookSameServiceButton}
                      block
                      onClick={handleBookSameService}
                      variant="secondary"
                      leftIcon={<Icon variant="calendar-plus" />}
                      label={_s(`${baseTranslationKey}.footer.cta.bookSameServiceAgain`)}
                      size="md"
                    />
                  )}
                  {user && (
                    <LinkButton
                      block
                      to={`/bokning/${booking.id}`}
                      variant="secondary"
                      leftIcon={<Icon variant="calendar-empty" />}
                      label={_s(`${baseTranslationKey}.footer.cta.viewBookingDetails`)}
                      size="md"
                    />
                  )}
                  <LinkButton
                    to={bookingOriginator}
                    block
                    variant="link"
                    label={_s(`${baseTranslationKey}.footer.cta.close`)}
                    size="md"
                  />
                </div>
              </CardWrapper>
            </div>
            <div className="hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full bg-white">
                {checkoutDetailsProps && (
                  <CheckoutDetails {...checkoutDetailsProps} services={services} giftcards={giftcards} />
                )}
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>
      <AddToCalendarModal onClose={handleCloseCalendarModal} {...addToCalendarProps} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default BookingConfirmation;
