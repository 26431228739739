import { paymentCardBrandSchema } from '@/types/paymentcards';
import { placeSchema } from '@/types/state/shared';
import { z } from 'zod';
import { bookingSchema } from '../booking/schema';

const CLIENT_BUNDLE_STATE = {
  VALID: 0,
  INVALID: 1,
  EXPIRED: 2,
} as const;

const clientBundleStateSchema = z.union([
  z.literal(CLIENT_BUNDLE_STATE.VALID),
  z.literal(CLIENT_BUNDLE_STATE.INVALID),
  z.literal(CLIENT_BUNDLE_STATE.EXPIRED),
]);

const adyenBundlePaymentStatusSchema = z.union([
  z.literal('Authorisation'),
  z.literal('Capture'),
  z.literal('Refund'),
  z.literal('Cancellation'),
  z.literal('CancelOrRefund'),
  z.literal('RefundFailed'),
  z.literal('CaptureFailed'),
]);

const bundlePaymentMethodSwishSchema = z.object({
  type: z.literal('Swish'),
  status: z.literal('success'),
});
const bundlePaymentMethodQliroSchema = z.object({
  type: z.literal('Qliro'),
  status: z.union([z.literal('InProgress'), z.literal('Completed')]),
});

const bundlePaymentMethodCardOnFileSchema = z.object({
  type: z.literal('StoredCard'),
  status: adyenBundlePaymentStatusSchema,
  cardDetails: z
    .object({
      brand: z.object({ id: paymentCardBrandSchema, name: z.string() }),
      lastFour: z.string().nullable(),
    })
    .optional(),
});
const bundlePaymentMethodApplePaySchema = z.object({
  type: z.literal('ApplePay'),
  status: adyenBundlePaymentStatusSchema,
});
const bundlePaymentMethodGooglePaySchema = z.object({
  type: z.literal('GooglePay'),
  status: adyenBundlePaymentStatusSchema,
});

const bundlePaymentMethodSchema = z.union([
  bundlePaymentMethodSwishSchema,
  bundlePaymentMethodQliroSchema,
  bundlePaymentMethodCardOnFileSchema,
  bundlePaymentMethodApplePaySchema,
  bundlePaymentMethodGooglePaySchema,
]);

export const bundleOtherPlaceStateSchema = placeSchema
  .pick({
    id: true,
    about: true,
  })
  .extend({
    service: z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string().optional(),
    }),
  });

export type ClientBundleOtherLocation = z.infer<typeof bundleOtherPlaceStateSchema>;

export const clientBundleSchema = z.object({
  id: z.number(),
  bundleTemplateId: z.number(),
  name: z.string(),
  description: z.string().optional(),
  validity: z.string().optional(),
  durationLabel: z.string().optional(),
  validMonths: z.number(),
  isArchived: z.boolean(),
  price: z.number(),
  priceLabel: z.string().optional(),
  finalPrice: z.number().optional(),
  finalPriceLabel: z.string().optional(),
  place: placeSchema.pick({ id: true, about: true }),
  places: z.array(bundleOtherPlaceStateSchema).optional(),
  vat: z.number(),
  state: clientBundleStateSchema,
  bookings: z.array(bookingSchema).optional(),
  paymentMethod: bundlePaymentMethodSchema,
  service: z.object({
    serviceId: z.number(), // <- mp service identifier
    name: z.string(),
    slug: z.string(),
    quantity: z.number(),
    usages: z.number(),
  }),
});

export type ClientBundle = z.infer<typeof clientBundleSchema>;

export const getBundlesByTabResponseSchema = z.object({
  bundles: z.array(clientBundleSchema),
  othersExist: z.boolean(),
});

export type GetBundlesByTabResponse = z.infer<typeof getBundlesByTabResponseSchema>;

export const getBundleByIdResponseSchema = clientBundleSchema;

export type GetBundleByIdResponse = z.infer<typeof getBundleByIdResponseSchema>;
