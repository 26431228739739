import { bookActions } from '@/actions';
import BundleCard from '@/components/elements/Card/BundleCard/BundleCard';
import BundleOtherLocationsListItem from '@/components/elements/lists/BundleOtherLocationsListItem/BundleOtherLocationsListItem';
import Header from '@/components/elements/Header/Header';
import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import EmptyState from '@/components/templates/EmptyState';
import { SCREEN_NAME } from '@/constants/analytics';
import {
  BOOK_TIME_ACTION_ITEM,
  CONSUMED_BUNDLE_LABEL,
  EXPIRED_BUNDLE_LABEL,
  VALID_BUNDLE_LABEL,
} from '@/constants/bundle';
import { CALENDAR_ICON_FILENAME } from '@/constants/icons';
import { setBookingStartingPoint, trackMpEvent } from '@/helpers';
import { 
  getBundleListContentProps, 
  isBundleValid, 
  getOtherBundleLocations 
} from '@/helpers/bundle';
import { _s } from '@/locale';
import { BundleTab } from '@/services';
import { ClientBundle } from '@/types/api/services/users/schema';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

const Bundle = ({ bundle, tab }: { bundle: ClientBundle; tab: BundleTab }) => {
  const dispatch = useDispatch();
  const bookWithBundlePathname = `/boka-tjanst/${bundle.place.about.slug}-${bundle.place.id}/${bundle.service.slug}-${bundle.service.serviceId}`;

  const handleUseBookingBundle = () => {
    setBookingStartingPoint('my_bundles');
    dispatch(bookActions.applyBundle(bundle));
  };

  const label = (() => {
    switch (tab) {
      case 'valid':
        return VALID_BUNDLE_LABEL;
      case 'expired':
        return EXPIRED_BUNDLE_LABEL;
      case 'used':
        return CONSUMED_BUNDLE_LABEL;
      default:
        const never: never = tab;
        console.error(`Unknown tab: ${never}`);
        return undefined;
    }
  })();

  const otherPlaces =
    isBundleValid(bundle) && bundle?.places ? bundle.places.filter((p) => p.id !== bundle?.place?.id) : [];
  const otherLocationsItemProps = getOtherBundleLocations(otherPlaces);

  const handleOtherLocationBundleActionClick = (bundle: ClientBundle, otherPlaceId: number) => {
    setBookingStartingPoint('my_bundles');
    trackMpEvent(`${BOOK_TIME_ACTION_ITEM.identifier}_click`, { trigger_source: SCREEN_NAME.MY_BUNDLE_OVERVIEW });

    const otherPlace = bundle.places.find((place) => place.id === otherPlaceId);

    // Create a new bundle object
    const otherPlaceBundle = {
      ...bundle,
      place: {
        ...bundle.place,
        id: otherPlace.id,
        about: otherPlace.about,
      },
      service: {
        ...bundle.service,
        serviceId: otherPlace.service.id,
        slug: otherPlace.service.slug,
      },
    };

    dispatch(bookActions.applyBundle(otherPlaceBundle));
  };

  return (
    <BundleCard
      {...getBundleListContentProps(bundle)}
      src={`/klippkortdetaljer/${bundle.id}`}
      label={label}
      {...(tab === 'valid' && {
        bottomSlot: (
          <>
            <LinkButton
              to={bookWithBundlePathname}
              variant="primary"
              block
              size="md"
              onClick={handleUseBookingBundle}
              label={BOOK_TIME_ACTION_ITEM.label}
              leftIcon={<Icon variant={CALENDAR_ICON_FILENAME.CALENDAR_1} />}
            />

            {otherLocationsItemProps?.length > 0 && (
              <div className="mt-md pt-sm border-t border-black-100">
                <Header label={_s(`pages.klippkort.Bundles.otherLocations.title`)} size="lg" />

                {otherLocationsItemProps.map((otherLocation) => (
                  <BundleOtherLocationsListItem
                    key={otherLocation.id}
                    onClick={() => handleOtherLocationBundleActionClick(bundle, otherLocation.id)}
                    {...otherLocation}
                  />
                ))}
              </div>
            )}
          </>
        ),
      })}
    />
  );
};

const BundlesList = ({
  selectedTab,
  bundles,
  hideEmptyStateBody,
}: {
  selectedTab: BundleTab;
  bundles: ClientBundle[];
  hideEmptyStateBody: boolean;
}) => {
  const baseTranslationKey = `components.modules.pages.klippkort.BundlesList.${selectedTab}`;
  const message = hideEmptyStateBody ? '' : _s(`${baseTranslationKey}.emptystate.body`);
  const title = _s(`${baseTranslationKey}.emptystate.title`);
  const cta =
    selectedTab === 'valid' ? (
      <LinkButton
        to="/vad/var?bundles=1"
        variant="primary"
        size="sm"
        label={_s(`${baseTranslationKey}.emptystate.cta`)}
      />
    ) : undefined;

  return (
    <div id={selectedTab} role="tabpanel" aria-labelledby={`${selectedTab}-tab`}>
      {bundles.length ? (
        <Fragment>
          {bundles.map((bundle) => (
            <div key={bundle.id} className="mb-md">
              <Bundle bundle={bundle} tab={selectedTab} />
            </div>
          ))}
        </Fragment>
      ) : (
        <EmptyState
          cta={cta}
          body={message}
          title={title}
          icon={<img src="/images/illustrations/bundles.png" alt="" />}
        />
      )}
    </div>
  );
};

export default BundlesList;
