import { Button, LinkButton } from '@/components/elements/forms/buttons';
import {
  getCookie,
  initAmplitudeAnalytics,
  isGoogleCrawler,
  isServer,
  sendPageView,
  setCookiePreferences,
} from '@/helpers';
import { __, _s } from '@/locale';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { initTracking } from '@/App.hooks';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import { AmplitudeExperimentContextType } from '@/hooks/useAmplitudeExperiment';
import Modal from './redesign/Modal/Modal';

type AllowCookiesProps = { onAllowCallback?: () => void } & AmplitudeExperimentContextType;

const handleClickModal = (e: MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

const AllowCookies = ({ initialize: initializeAmplitudeExperiment, onAllowCallback }: AllowCookiesProps) => {
  const [show, setShow] = useState(true);
  const location = useLocation();
  const cookieAccepted = Boolean(getCookie('BokadirektCookiePreferencesMP'));

  useEffect(() => {
    if (cookieAccepted) setShow(false);
  }, [cookieAccepted]);

  if (isServer) return null;
  if (window.location.pathname === '/articles/cookies') return null;
  if (isGoogleCrawler()) return null;

  const acceptCookie = () => {
    setCookiePreferences();
    setShow(false);
    initAmplitudeAnalytics();
    initTracking();
    sendPageView();
    initializeAmplitudeExperiment();
    onAllowCallback?.();
  };

  return (
    <Modal isOpen={show} shouldCloseOnOverlayClick={false}>
      <Modal.Content floating>
        <Modal.Header title={_s('allowCookiesTitle')} />
        <div className="flex flex-col" onClick={handleClickModal}>
          <div className="pb-xxl text-md">
            <span className="text-black-600 whitespace-pre-wrap">{_s('allowCookiesContent')}&nbsp;</span>
            <Link
              to={{ pathname: '/articles/cookies', state: { fromAllowCookiesPopup: location.pathname } }}
              className="underline">
              {_s('readMore')}
              <span className="sr-only"> {_s('aboutOurCookiePolicy')}</span>
            </Link>
          </div>
          <div className="space-y-lg flex flex-col">
            <Button size="md" data-cy="allowCookiesButton" onClick={acceptCookie}>
              {_s('allowCookiesButton')}
            </Button>
            <LinkButton
              size="md"
              to={{ pathname: '/articles/cookies', state: { fromAllowCookiesPopup: location.pathname } }}
              style={{ textAlign: 'center' }}>
              {__('customise')}
            </LinkButton>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(withAmplitudeExperiment(AllowCookies));
