import { getCookie, server } from '../helpers';
import { __ } from '../locale';

export const autocompleteService = {
  getSuggestions,
  getLocationSuggestions,
  deleteLocationSuggestion,
};

function getSuggestions(query: string, isSistaminutenSearch: boolean) {
  query = (query || '').split('%25').join('%').split('%').join('%25');
  return server.request
    .get('/search/suggestions?q=' + encodeURIComponent(query) + (isSistaminutenSearch ? '&sistaminuten=1' : ''))
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getLocationSuggestions(query) {
  query = query.split('%25').join('%').split('%').join('%25');
  const locationData = getCookie('locationData');
  let requestUrl = `/autocomplete/location?q=${query}`;

  if (locationData && locationData.location && locationData.location === __('currentLocation')) {
    const [lat, lon] = locationData.position.split('-');
    if (lat && lon) {
      requestUrl += `&lat=${lat}&lon=${lon}`;
    }
  }

  return server.request.get(requestUrl).then(server.handleSuccess).catch(server.handleError);
}

function deleteLocationSuggestion(location: string) {
  const requestPayload = {
    location: location,
  };
  return server.request
    .post('/autocomplete/location/remove', requestPayload)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
