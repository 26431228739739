import { modalActions, placeActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import HR from '@/components/elements/HR/HR';
import Label from '@/components/elements/Label/Label';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import BannerSlot from '@/components/modules/banner/BannerSlot';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import { Map } from '@/components/modules/map';
import { AndroidTopBanner, IPhoneTopBanner } from '@/components/modules/mobile';
import BookAppointmentModal from '@/components/modules/modals/BookAppointment/BookAppointment';
import UserLocationOnboarding from '@/components/modules/modals/UserLocationOnboarding';
import FooterNavigation from '@/components/modules/navigation/FooterNavigation';
import FooterNavigationContainer from '@/components/modules/navigation/FooterNavigationContainer';
import NavBar from '@/components/modules/navigation/navbar/NavBar/NavBar';
import { SERPTopInfoBanner } from '@/components/modules/pages/serp';
import Pagination, { hasNextPage } from '@/components/modules/pages/serp/Pagination';
import SearchInfoWithPopup from '@/components/modules/pages/serp/SearchInfoWithPopup';
import { PlaceCardContainer } from '@/components/modules/place';
import { BANNER_SLOT_LOCATION } from '@/constants/banner';
import { MAPTILER_FEATURE_FLAG } from '@/constants/experimentConstants';
import {
  classnames,
  getSERPBreadcrumbs,
  isSistaminuten,
  setBookingStartingPoint,
  showAndroidTopBanner,
  showIphoneTopBanner,
} from '@/helpers';
import { useAppSelector } from '@/hooks';
import { useSearchContext } from '@/hooks/search/useSearch';
import { DEFAULT_MY_LOCATION_KEYWORD } from '@/hooks/search/useSearch.constants';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { useHandlePopstate } from '@/hooks/useHandlePopState';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useKeywordAutoSuggestManager from '../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest.hooks';
import useLocationAutoSuggestManager from '../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest.hooks';
import useDateTimePickerManager from '../components/DateTimePicker/DateTimePicker.hooks';
import { DateTimePickerState } from '../components/DateTimePicker/DateTimePicker.types';
import useViewToggle from '../components/ViewToggle/ViewToggle';
import { SEARCH_RESULT_PER_PAGE_LIMIT } from '../constants';
import { getPageScreenName, trackPlaceClick } from '../helpers/tracking';
import SearchControls from '../modules/SearchControls';
import SearchEmptyState from '../modules/SearchEmptyState';
import SearchForm from '../modules/SearchForm/SearchForm';
import SEO from '../modules/SEO';
import { getSearchParamsForMatchedServices, getSearchResultsPageHeadingProps } from './index.helpers';

const baseTranslationKey = 'features.searchV2.pages.SearchResultsPage';

const SEARCH_PAGE_VIEWS = [
  { view: 'list', icon: 'list-ol', label: _s('listView') },
  { view: 'map', icon: 'map', label: _s('mapView') },
] as const;

const SearchResultsPage = () => {
  const searchManager = useSearchContext();
  const flags = useAppSelector((state) => state.flags);
  const searchV2 = useAppSelector((state) => state.searchV2);
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { isMobileView } = useMobileView();
  const popstate = useHandlePopstate();
  const navigation = useLocation();

  const { q, location, page, prefs, startDate, endDate, timeOfDay, performSearch, paginate, type } = searchManager;
  const {
    places = [],
    profilePages = [],
    fetching,
    fetchingExtendedPlaces,
    topSearch,
    showInjectionNotice,
    results,
    extendSearchDisabled,
    bounds,
  } = searchV2;

  const keywordAutoSuggestManager = useKeywordAutoSuggestManager({
    initialState: {
      keyword: q,
      suggestions: [],
      newServices: [],
      showSuggestions: true,
    },
    isSistaminutenSearch: searchManager.type === 'sistaminuten',
    onSelectKeywordSuggestion: handleOnSelectKeywordSuggestion,
  });

  const locationAutoSuggestManager = useLocationAutoSuggestManager({
    initialState: {
      locationValue: location,
      hasHistory: false,
      suggestions: [],
      storedSuggestions: [],
      showSuggestions: true,
      isFetchingUserPosition: false,
    },
    onSelectLocationSuggestion: handleOnUpdateLocation,
  });

  const dateTimePickerManager = useDateTimePickerManager({
    initialState: {
      startDate: startDate,
      endDate: endDate,
      timeOfDay: timeOfDay,
      showCalendar: false,
    },
    onUpdateDateTime: handleOnUpdateDateTime,
    onClearDateTime: handleOnClearDateTime,
  });

  const viewToggle = useViewToggle({
    views: [...SEARCH_PAGE_VIEWS],
    initialState: SEARCH_PAGE_VIEWS[0],
  });

  const isSistaminutenSearch = type === 'sistaminuten';
  const hasProfilePlaces = profilePages?.length > 0;
  const noMatchingSearchResults = Boolean(!places[page]?.length && !fetching) && !hasProfilePlaces;
  const topOfSearchPlaces = page < 1 ? topSearch?.places ?? [] : [];
  const topPlacesEntries = !page && topSearch && topSearch.places ? topSearch.places.length : 0;
  const serpPageNo = page ? +page + 1 : 1;
  const source = prefs?.indexOf('5') !== -1 ? 'serp-page-top' : 'serp-page';
  const breadcrumbs = getSERPBreadcrumbs(q, location, isSistaminutenSearch);
  const heading = getSearchResultsPageHeadingProps(searchManager);
  const showUserLocationOnboarding = !modal.login?.show && modal.userLocationOnboarding?.show;
  const pageScreenName = getPageScreenName(type, viewToggle.view, isMobileView);
  const hasNextPageResults = hasNextPage(searchV2.results, searchManager.page || 0, SEARCH_RESULT_PER_PAGE_LIMIT);
  const canExtendCurrentPage = places[page]?.length <= SEARCH_RESULT_PER_PAGE_LIMIT;
  const shouldRenderExtendedSearch = Boolean(
    !extendSearchDisabled && searchManager.location && !fetching && canExtendCurrentPage && !bounds,
  );

  // experiment with maptiler
  const useMapTilerExperiment = useGetAmplitudeExperimentVariant()(MAPTILER_FEATURE_FLAG)?.value === 'on';

  const mapProps = {
    q,
    location,
    places: [...(topOfSearchPlaces || []), ...(places[page] || [])],
    fetching: Boolean(fetching || fetchingExtendedPlaces),
    position: searchManager.lat && searchManager.lon && { position: `${searchManager.lat}-${searchManager.lon}` },
    onMapBoundsChange: handleOnMapBoundsChange,
    useMapTiler: useMapTilerExperiment,
  };

  const topBannerConfig = flags?.[BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH]?.payload;
  const middleBannerMobileConfig =
    isMobileView &&
    results > 0 &&
    (flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH]?.payload ||
      flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB]?.payload);
  const middleBannerMobileLocationId = flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH]?.payload
    ? BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH
    : BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB;
  const middleBannerDesktopConfig = !isMobileView && flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB]?.payload;

  function handleOnClearDateTime() {
    performSearch({ startDate: null, endDate: null, timeOfDay: null });
  }

  function handleOnUpdateDateTime(state: DateTimePickerState) {
    dateTimePickerManager.onCloseCalendar();
    performSearch({ startDate: state.startDate, endDate: state.endDate, timeOfDay: state.timeOfDay });
  }

  function handleOnUpdateLocation(params) {
    performSearch({ ...params, q });
  }

  function handleOnSelectKeywordSuggestion(suggestion) {
    performSearch({ location, q: suggestion }, { searchContext: 'search_autocomplete' });
  }

  function handleOnRequestExtendedSearchClick() {
    performSearch({}, { extendedSearch: true });
  }

  function handleOnRequestUserLocation() {
    dispatch(modalActions.userLocationOnboarding({ show: false }));
    locationAutoSuggestManager.onFetchAndSetUserLocation(DEFAULT_MY_LOCATION_KEYWORD);
  }

  function handleOnClickPlace(place, serpIndex, hasDiscount = false) {
    trackPlaceClick(searchManager, place, serpIndex, hasDiscount, pageScreenName);
    setBookingStartingPoint(pageScreenName, searchManager.q);
    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.SERP_TO_PLACE_CLICK);
  }

  function handleNextPageClick() {
    if (!hasNextPageResults) return;
    paginate().nextPage();
    window.scrollTo(0, 0);
  }

  function handlePrevPageClick() {
    if (page <= 0) return;
    paginate().prevPage();
    window.scrollTo(0, 0);
  }

  function handleOnMapBoundsChange(bounds: string) {
    performSearch({}, { bounds });
  }

  const prepend = !isSistaminuten() ? (
    showIphoneTopBanner() ? (
      <IPhoneTopBanner source={pageScreenName} />
    ) : showAndroidTopBanner() ? (
      <AndroidTopBanner source={pageScreenName} />
    ) : null
  ) : null;

  useEffect(() => {
    // switch to list view if not on mobile
    if (!isMobileView && viewToggle.view === 'map') {
      viewToggle.setView(SEARCH_PAGE_VIEWS[0]);
    }
  }, [isMobileView, viewToggle]);

  useEffect(
    () => {
      /**
       * If the url has been updated by the browser navigation
       * change the input values to match the url and perform a new search
       * to sync the search results with the url
       */
      let shouldSyncSearchResults = false;

      if ((popstate.didUpdate || navigation) && keywordAutoSuggestManager.keyword !== q) {
        keywordAutoSuggestManager.onChange(null, { newValue: q });
        shouldSyncSearchResults = true;
      }

      if ((popstate.didUpdate || navigation) && locationAutoSuggestManager.location !== location) {
        locationAutoSuggestManager.onChange({ target: { value: location } });
        shouldSyncSearchResults = true;
      }

      if ((popstate.didUpdate || navigation) && searchV2.isSistaminutenSearch !== isSistaminutenSearch) {
        shouldSyncSearchResults = true;
      }

      if (shouldSyncSearchResults && !searchV2.isSSRFetched) {
        searchManager.performSyncedSearch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popstate.didUpdate, navigation],
  );

  useEffect(() => {
    if (fetching) window.scrollTo(0, 0);
  }, [fetching]);

  return (
    <div className={classnames(isSistaminutenSearch ? 'bg-gradient-smt' : 'bg-gradient')}>
      <NavBar
        source="list"
        navClass="list-container navigation"
        defaultNavBarHeight={174}
        prepend={prepend}
        headerSlot={
          <div className="pb-sm">
            <div className="px-lg w-full xl:w-8/12">
              <SearchForm
                searchManager={searchManager}
                keywordAutoSuggestManager={keywordAutoSuggestManager}
                locationAutoSuggestManager={locationAutoSuggestManager}
                dateTimePickerManager={dateTimePickerManager}
              />
            </div>
            {!isSistaminuten() ? (
              <div
                className={classnames(
                  'pl-lg mt-sm space-x-sm flex',
                  isMobileView && 'no-scrollbar flex overflow-x-scroll',
                )}>
                {!isSistaminutenSearch && <SearchControls {...searchManager} />}
                {isMobileView && (
                  <>
                    {viewToggle.render}
                    {/* just a spacer for horizontal scroll */}
                    <div className="pl-sm"></div>
                  </>
                )}
              </div>
            ) : (
              <div className="py-2"></div>
            )}
          </div>
        }
      />

      {viewToggle.view === 'map' && <Map {...mapProps} columnClass="full" />}
      {viewToggle.view === 'list' && (
        <div className="lg:w-7/12">
          <div></div>
          <div className="ml-0 !pt-0">
            {showInjectionNotice && q.length > 0 && <SERPTopInfoBanner variant="injectionInfo" important />}
            {topBannerConfig && (
              <BannerSlot config={topBannerConfig} locationId={BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH} />
            )}
            <div className="container-screen relative">
              <Breadcrumbs items={breadcrumbs} className="text-sm md:text-base" />
              {fetching && <LoadingPlaceHolder className="z-[99]" />}
              <div className="pt-lg">
                {noMatchingSearchResults ? (
                  <SearchEmptyState
                    {...searchManager}
                    showExtendedSearch={shouldRenderExtendedSearch}
                    onRequestExtendedSearchClick={handleOnRequestExtendedSearchClick}
                  />
                ) : (
                  <Fragment>
                    {isSistaminutenSearch && (
                      <div className="space-y-md flex w-full flex-col">
                        <div className="flex w-full items-center">
                          <Icon variant="smt-filled" color="bg_accent_5" size="md" />
                          <h2 className="ml-xs text-h-m  md:text-h-l font-semibold">Sista Minuten</h2>
                          <span className="ml-auto inline-block">
                            <Label variant="smt" label={_s('Novelty!')} icon={false} className="ml-auto" />
                          </span>
                        </div>
                        <p className="text-l md:text-xl">{_s(`${baseTranslationKey}.sistaminutenDescription`)}</p>
                        <h1 className="text-l flex p-0 font-semibold md:text-xl">
                          <span className="space-x-xs flex items-start">
                            <Icon variant="search-check" />
                            <span>{heading.title}</span>
                          </span>
                          {results > 0 && (
                            <span className="pl-md relative my-auto ml-auto inline whitespace-nowrap">
                              <span className="text-black-600 text-sm font-normal">
                                {results && results > 0 ? results + ` ${_s('hits')}` : ''}
                              </span>
                            </span>
                          )}
                        </h1>
                      </div>
                    )}
                    {!isSistaminutenSearch && (
                      <Fragment>
                        <h1 className="md:text-h-m p-0 text-base font-semibold">
                          {heading.title}
                          {results > 0 && (
                            <span className="relative inline whitespace-nowrap">
                              &nbsp;&middot;&nbsp;
                              <span className="text-black-600 text-sm font-normal">
                                {results && results > 0 ? results + ` ${_s('hits')}` : ''}
                              </span>
                            </span>
                          )}
                        </h1>
                        <p>{heading.description}</p>
                      </Fragment>
                    )}
                    <SearchInfoWithPopup />
                  </Fragment>
                )}
              </div>
              {middleBannerMobileConfig && (
                <div className="py-md">
                  <BannerSlot config={middleBannerMobileConfig} locationId={middleBannerMobileLocationId} />
                </div>
              )}
              {middleBannerDesktopConfig && (
                <div className="py-lg">
                  <BannerSlot
                    config={middleBannerDesktopConfig}
                    locationId={BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB}
                    inContainer={false}
                  />
                </div>
              )}
              <div className="space-y-md">
                {topOfSearchPlaces?.map((place, key) => {
                  const serpIndex = key;
                  return (
                    <PlaceCardContainer
                      source="serp-page-top"
                      place={place}
                      key={serpIndex}
                      SerpPageNo={serpPageNo}
                      SerpIdx={serpIndex}
                      onClickPlace={() => handleOnClickPlace(place, serpIndex, true)}
                      fireHover={() => dispatch(placeActions.setHover(place.id))}
                      fireHoverOut={() => dispatch(placeActions.removeHover())}
                      searchParams={getSearchParamsForMatchedServices({ ...searchManager })}
                    />
                  );
                })}
                {places?.[page]?.map((place, key) => {
                  const serpIndex = key + topPlacesEntries;
                  return (
                    <PlaceCardContainer
                      source={source}
                      place={place}
                      key={serpIndex}
                      SerpPageNo={serpPageNo}
                      SerpIdx={serpIndex}
                      onClickPlace={() => handleOnClickPlace(place, serpIndex, false)}
                      fireHover={() => dispatch(placeActions.setHover(place.id))}
                      fireHoverOut={() => dispatch(placeActions.removeHover())}
                      searchParams={getSearchParamsForMatchedServices({ ...searchManager })}
                      isSistaminutenSearch={isSistaminutenSearch}
                    />
                  );
                })}
              </div>
            </div>

            {hasProfilePlaces && !hasNextPageResults && !isSistaminuten() && (
              <>
                <div className="py-md px-xl flex">
                  <div className="px-lg flex-1">
                    <HR className="bg-fg_tertiary"></HR>
                  </div>
                  <div className="text-center">
                    <h2 className="text-h-s text-fg_primary">Relaterade företag i {location || 'Sweden'}</h2>
                    <span className="text-s text-fg_secondary">Nedan företag saknar onlinebokning</span>
                  </div>

                  <div className="px-lg flex-1">
                    <HR className="bg-fg_tertiary"></HR>
                  </div>
                </div>
                <div className="px-md gap-md flex flex-col">
                  {profilePages.map((place, key) => {
                    const serpIndex = key;
                    return (
                      <PlaceCardContainer
                        source={'profilePages'}
                        place={place}
                        key={serpIndex}
                        SerpPageNo={serpPageNo}
                        SerpIdx={serpIndex}
                        onClickPlace={() => handleOnClickPlace(place, serpIndex, false)}
                        fireHover={() => dispatch(placeActions.setHover(place.id))}
                        fireHoverOut={() => dispatch(placeActions.removeHover())}
                        searchParams={getSearchParamsForMatchedServices({ ...searchManager })}
                      />
                    );
                  })}
                </div>
              </>
            )}

            <div className="container-screen">
              <div className="pb-lg">
                {shouldRenderExtendedSearch && !noMatchingSearchResults && !hasNextPageResults && (
                  <div className="p-md w-full text-center">
                    <p className="text-xl">{_s('extendSearchTitle')}</p>
                    <p className="text-l text-black-600">{_s('extendSearchSubTitle')}</p>
                    <Button
                      className="mt-xl"
                      disabled={fetchingExtendedPlaces}
                      onClick={handleOnRequestExtendedSearchClick}>
                      <span className="grid items-center" style={{ gridTemplateAreas: 'stack' }}>
                        <span
                          className={fetchingExtendedPlaces ? 'invisible' : 'visible'}
                          style={{ gridArea: 'stack' }}>
                          {_s('extendButton')}
                        </span>
                        <span
                          className={fetchingExtendedPlaces ? 'visible' : 'invisible'}
                          aria-label={_s('extendButtonLoading')}
                          style={{ gridArea: 'stack' }}>
                          <LoadingIcon style={{ height: 24, width: 24, margin: 'auto' }} />
                        </span>
                      </span>
                    </Button>
                  </div>
                )}
                <Pagination
                  itemsPerPage={SEARCH_RESULT_PER_PAGE_LIMIT}
                  nextPage={handleNextPageClick}
                  prevPage={handlePrevPageClick}
                  results={results}
                  page={page}
                />
              </div>
            </div>
            {!isMobileView && <div className="hidden lg:block">{<Map {...mapProps} />}</div>}
          </div>
        </div>
      )}
      <FooterNavigationContainer>
        <FooterNavigation />
      </FooterNavigationContainer>
      <BookAppointmentModal />
      {showUserLocationOnboarding && (
        <UserLocationOnboarding
          isOpen={showUserLocationOnboarding}
          onClose={() => dispatch(modalActions.userLocationOnboarding({ show: false }))}
          onRequestUserLocation={handleOnRequestUserLocation}
        />
      )}
      <SEO {...searchManager} />
    </div>
  );
};

export default SearchResultsPage;
