import { promiseWrapper } from '@/helpers';
import { autocompleteService } from '@/services';

export async function getSuggestionsFromSearchValue(value: string, isSistaminutenSearch: boolean) {
  const { data, error } = await promiseWrapper(autocompleteService.getSuggestions(value, isSistaminutenSearch));

  if (error) {
    return { suggestions: [], newServices: [] };
  }
  const fetched = data && Array.isArray(data.suggestions) ? data.suggestions : [];

  return {
    suggestions: fetched,
    newServices: data.newServices,
  };
}
