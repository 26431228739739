import { ListItem } from '@/components/elements/lists';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { BundlePlaceContentProps } from '../../lists/BundleCardListItem/BundleCardListItem';
import { BOOK_TIME_ACTION_ITEM } from '@/constants/bundle';
import { CALENDAR_ICON_FILENAME } from '@/constants/icons';

const BundleOtherLocationsListItem = (
  props: BundlePlaceContentProps & {
    onClick?: () => void;
  },
) => {
  return (
    <ListItem
      leftSlot={<CompanyAvatar alt={props.image?.alt ?? ''} size="md" imgUrl={props.image?.src ?? ''} />}
      verticalAlign="top"
      leftPadding={false}
      rightPadding={false}
      rightSlot={
        <LinkButton
          className="flex-shrink-0"
          to={props?.placeBookWithBundleUrl}
          onClick={props.onClick}
          variant="primary"
          block
          size="md"
          label={BOOK_TIME_ACTION_ITEM.label}
          leftIcon={<Icon variant={CALENDAR_ICON_FILENAME.CALENDAR_1} />}
        />
      }>
      <a href={props.placePageUrl}>
        <div className="gap-xs flex flex-col">
          <p className="text-black-900 text-md">{props.placeName}</p>
          {props.placeServiceName && (
            <div className="text-black-600 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
              <span>{props.placeServiceName}</span>
            </div>
          )}
        </div>
      </a>
    </ListItem>
  );
};

export default BundleOtherLocationsListItem;
